@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
p {
  font-family: "Poppins", sans-serif;
}

.display-1 {
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.75),
    -2px -2px 15px rgba(0, 0, 0, 0.75);
}

.image-wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transform: rotate(180deg);
  height: 100vh;
  width: 100vw;
  display: flex;
}

.image-body {
  box-shadow: 2px 2px 55px rgba(0, 0, 0, 0.25),
    -2px -2px 55px rgba(0, 0, 0, 0.25);
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transform: rotate(180deg);
  height: 90%;
  width: 90%;
  border-radius: 15px;
  display: flex;
  overflow: clip;
}
@media (min-width: 480px) {
  .image-body {
    background-size: 200%;
  } 
}
@media (min-width: 768px) and (min-height: 768px) {
  .image-body {
    background-size: 200%;
  } 
}
@media (min-width: 1024px) {
  .image-body {
    background-size: 200%;
  } 
}

.image-wrapper::before,
.image-body::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
}

.image-body::before {
  background-color: rgba(0, 0, 0, 0.25);
}

.input-class {
  border: 1px solid white;
  padding: 0.475rem;
  padding-left: 1rem;
  width: 250px;
  font-size: 12px;
  outline: 0;
  color: white;
  background-color: black;
}

.custom-hr-prev,
.custom-hr-next {
  position: relative;
}
.custom-hr-prev:before,
.custom-hr-next:before {
  position: absolute;
  color: white;
  top: -11px;
  content: ">";
  height: 100%;
  width: 100%;
  left: 3.5rem;
}
.custom-hr-prev:before {
  content: "<";
  left: -5px;
}

.swiper-button-disabled.custom-hr-prev:before,
.swiper-button-disabled.custom-hr-next:before {
  content: none;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 4rem;
}
@media (min-width: 480px) {
  .swiper-pagination {
    margin-bottom: 2rem;
  } 
}
@media (min-width: 768px) and (min-height: 768px) {
  .swiper-pagination {
    margin-bottom: 5rem;
  } 
}
@media (min-width: 1024px) {
  .swiper-pagination {
    display: none;
  } 
}

.swiper-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 100px;
  opacity: 0.5;
}

.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  width: 12px;
  height: 12px;
}
